import React from "react";
import Contact from "../components/contact/Contact";
import Footer from "../components/layout/footer/Footer";
import Navbar from "../components/layout/header/Navbar";
import Layout from "../components/layout/Layout";
import Subsribe from "../components/newsletter/Subsribe";
import PageHeader from "../components/team/PageHeader";
import Teams from "../components/team/Teams";
import BrandCarousel from "../components/testimonial/BrandCarousel";
import kickoff from "../logos/photos/kickoff.jpg"
import eaes from "../logos/photos/EAES.jpeg"
import surgery from "../logos/photos/surgery.jpg"
import image1 from "../logos/photos/134.jpg"
import image14 from "../logos/photos/444.png"
import { useNavigate } from 'react-router-dom';
import SubscribeForm from "../components/team/Subscribe";
import goslar from "../logos/photos/glosar.jpg"
import image12 from "../logos/photos/meetingtask.png"
import image3 from "../logos/photos/Image_20240627_60700_047.jpg"
import image22 from "../logos/photos/image22.jpg"
import image23 from "../logos/photos/unnamed.jpg"
import image16 from "../logos/photos/picture12.jpg"
import image17 from "../logos/photos/image11.png"
import logo2 from "../logos/photos/logoplp.png"
import logo12 from "../logos/photos/thumbnail.jpg"

export default function News() {
  const navigate = useNavigate();
  function handleClick1() {

    navigate('/PALPABLE_project_kicks_off_with_a_bang');
  }
  function handleClick2() {

    navigate('/The_EAES_congress_in_Rome_embraces_the_PALPABLE_M6_meeting');
  }
  function handleClick4() {

    navigate('/PALPABLE experts consult EAES surgeons in Maastricht');
  }
  function handleClick5() {

    navigate('/PALPABLE consortium explores latest project advances at Queen Mary University');
  }
  function handleClick6() {

    navigate('/PALPABLE partners co-host workshop exploring the role of touch in surgery at Hamlyn Symposium');
  }
  function handleClick7() {

    navigate('/Latest PALPABLE research presented at TAROS 2024');
  }
  function handleClick8() {

    navigate('/New Research Explores Integration of PALPABLE Technology with Robotic Surgery Systems');
  }

  function handleClick9() {

    navigate('/Research Showcases Capabilities of PALPABLE Optical Fiber-Based Force Sensor');
  }
  function handleClick3() {

    navigate('/PALPABLE_partners_meet_at_Fraunhofer_HHI_to_discuss_progress_and_future_plans');
  }
  function BlogClick1() {

    navigate('/ADDRESSING_A_DRAWBACK_OF_MINIMALLY_INVASIVE_SURGERY');
  }
  function BlogClick2() {

    navigate('/THE ROAD AHEAD FOR MINIMALLY INVASIVE THERAPY OF DIGESTIVE CANCERS');
  }
  function BlogClick3() {

    navigate('/Optical fibre sensors: From oil rigs to minimally invasive surgery');
  }
  function BlogClick4() {

    navigate('/Inflated Haptic Membrane Sensors: Bringing Back the Surgeon’s Touch');
  }
  function BlogClick5() {

    navigate('/Engineering Flexibility: How Flexible Optical Sensors Could Transform Surgical Tools');
  }
  return (
    <Layout>
      <Navbar />
      <PageHeader HeaderTitle="Newsroom" Parent="Pages" PageTitle="Newsroom" />
      <section className="promo-section mt-5 ptb-100" id='newses'>
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-10">
            <h3 style={{color:'#12b59e'}}>
                  News
                  </h3>
            </div>
            <div class="container">
            <div class="card-deck my-3">

            <div class="card" style={{cursor:"pointer"}} onClick={handleClick9}>
      <img class="card-img-top" src={logo12}  alt="Card image cap"/>
      <div class="card-body">
        <h5 class="card-title">Research Showcases Capabilities of PALPABLE Optical Fiber-Based Force Sensor</h5>
        <p class="card-text">The PALPABLE consortium has developed an innovative optical fiber-based force sensor that could significantly improve tactile feedback during minimally invasive surgery (MIS)...</p>
        <p class="card-text" ><small class="text-muted">11/11/2024</small><span className="span2">Read more</span></p>
      </div>
    </div>

            <div class="card" style={{cursor:"pointer"}} onClick={handleClick8}>
      <img class="card-img-top" src={logo2}  alt="Card image cap"/>
      <div class="card-body">
        <h5 class="card-title">New Research Explores Integration of PALPABLE Technology with Robotic Surgery Systems</h5>
        <p class="card-text">A groundbreaking master's thesis conducted at the Universidad Politécnica de Madrid has laid the foundation for integrating PALPABLE's innovative haptic feedback technology...</p>
        <p class="card-text" ><small class="text-muted">29/10/2024</small><span className="span2">Read more</span></p>
      </div>
    </div>

            <div class="card" style={{cursor:"pointer"}} onClick={handleClick7}>
      <img class="card-img-top" src={image23} alt="Card image cap"/>
      <div class="card-body">
        <h5 class="card-title">Latest PALPABLE research presented at TAROS 2024</h5>
        <p class="card-text">PALPABLE was proudly featured at this year’s Towards Autonomous Robotic Systems conference (TAROS), hosted by Brunel University London from 21-23 August 2024...</p>
        <p class="card-text" ><small class="text-muted">28/08/2024</small><span className="span2">Read more</span></p>
      </div>
    </div>

      
    

   

   
            </div>
  <div class="card-deck my-3">
  <div class="card" style={{cursor:"pointer"}} onClick={handleClick6}>
      <img class="card-img-top" src={image22} alt="Card image cap"/>
      <div class="card-body">
        <h5 class="card-title">PALPABLE partners co-host workshop exploring the role of touch in surgery at Hamlyn Symposium</h5>
        <p class="card-text">The recent workshop "From Virtual Palpation to Clinical Integration: Exploring the Role of Touch in Medicine and Surgery," held at the prestigious Hamlyn Symposium, ...</p>
        <p class="card-text" ><small class="text-muted">28/06/2024</small><span className="span2">Read more</span></p>
      </div>
    </div>

  <div class="card" style={{cursor:"pointer"}} onClick={handleClick5}>
      <img class="card-img-top" src={image3} alt="Card image cap"/>
      <div class="card-body">
        <h5 class="card-title">PALPABLE consortium explores latest project advances at Queen Mary University</h5>
        <p class="card-text">PALPABLE partners came together in London from the 26th to 27th of June to discuss the latest research developments and plan for the coming months...</p>
        <p class="card-text" ><small class="text-muted">01/07/2024</small><span className="span2">Read more</span></p>
      </div>
    </div>

  <div class="card" style={{cursor:"pointer"}} onClick={handleClick4}>
      <img class="card-img-top" src={image12} alt="Card image cap"/>
      <div class="card-body">
        <h5 class="card-title">PALPABLE experts consult EAES surgeons in Maastricht</h5>
        <p class="card-text">This week PALPABLE experts met with the European Association for Endoscopic Surgery’s PALPABLE Task Force in the Netherlands, a group of surgeons specializing  ...</p>
        <p class="card-text" ><small class="text-muted">13/06/2024</small><span className="span2">Read more</span></p>
      </div>
    </div>


  
    
   
  </div>
  <div class="card-deck my-3">
  <div class="card" style={{cursor:"pointer"}} onClick={handleClick3}>
      <img class="card-img-top" src={goslar} alt="Card image cap"/>
      <div class="card-body">
        <h5 class="card-title">PALPABLE partners meet at Fraunhofer HHI to discuss progress and future plans</h5>
        <p class="card-text">At the end of November, Fraunhofer HHI welcomed PALPABLE partners to their premises in snowy Goslar to discuss  progress and ...</p>
        <p class="card-text" ><small class="text-muted">21/12/2023</small><span className="span2">Read more</span></p>
      </div>
    </div>
 
  <div class="card" style={{cursor:"pointer"}} onClick={handleClick2}>
      <img class="card-img-top" src={eaes} alt="Card image cap"/>
      <div class="card-body">
        <h5 class="card-title">The EAES congress in Rome embraces the PALPABLE M6 meeting</h5>
        <p class="card-text">On June 23rd, our second physical PALPABLE meeting occurred in Rome, marking six months
since the project officially commenced...</p>
        <p class="card-text"><small class="text-muted">28/6/2023</small> <span className="span2">Read more</span></p>
        
      </div>
    </div>

  <div class="card" style={{cursor:"pointer"}} onClick={handleClick1}>
      <img class="card-img-top" src={kickoff} alt="Card image cap"/>
      <div class="card-body">
        <h5 class="card-title">PALPABLE project kicks off with a bang</h5>
        <p class="card-text">The 14th-15th of February 2023 found the PALPABLE partners (Hellenic Mediterranean University, Medtronic, Bendabl, Università degli Studi di Torino, Fraunhofer HHI, ...</p>
        <p class="card-text" ><small class="text-muted">21/2/2023</small><span className="span2">Read more</span></p>
      </div>
    </div>
  </div>
</div>
          
          </div>
   
        </div>
      </section>
       <section id='blogposts' className="promo-section mt-5 ptb-100" style={{backgroundColor:'#095a4f'}}>
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-10">
            <h3 style={{color:'whitesmoke'}}>
                  Blogposts
                  </h3>
            </div>
            <div class="container">
        
        <div class="card-deck my-3">
        <div class="card" style={{cursor:"pointer"}} onClick={BlogClick5}>
      <img class="card-img-top" src={image17} alt="Card image cap"/>
      <div class="card-body">
        <h5 class="card-title">Engineering Flexibility: How Flexible Optical Sensors Could Transform Surgical Tools </h5>
        <p class="card-text">Tissue palpation is a critical diagnostic technique used by clinicians to identify abnormality within tissue...</p>
        <p class="card-text" ><small class="text-muted">24/9/2024</small><span className="span2">Read more</span></p>
      </div>
      </div>
          
        <div class="card" style={{cursor:"pointer"}} onClick={BlogClick4}>
      <img class="card-img-top" src={image16} alt="Card image cap"/>
      <div class="card-body">
        <h5 class="card-title">Inflated Haptic Membrane Sensors: Bringing Back the Surgeon’s Touch</h5>
        <p class="card-text">Tissue palpation is a critical diagnostic technique used by clinicians to identify abnormality within tissue...</p>
        <p class="card-text" ><small class="text-muted">24/9/2024</small><span className="span2">Read more</span></p>
      </div>
      </div>

        <div class="card" style={{cursor:"pointer"}} onClick={BlogClick3}>
      <img class="card-img-top" src={image14} alt="Card image cap"/>
      <div class="card-body">
        <h5 class="card-title">Optical fibre sensors: From oil rigs to minimally invasive surgery</h5>
        <p class="card-text">The advancement of optical fiber sensors has made a great impact on precision monitoring and sensing technologies...</p>
        <p class="card-text" ><small class="text-muted">15/1/2025</small><span className="span2">Read more</span></p>
      </div>
      </div>

        
</div>
      
      </div>
  
          
          </div>    <div class="card-deck my-3">
                <div class="card" style={{cursor:"pointer"}} onClick={BlogClick2}>
      <img class="card-img-top" src={image1} alt="Card image cap"/>
      <div class="card-body">
        <h5 class="card-title">THE ROAD AHEAD FOR MINIMALLY INVASIVE THERAPY OF DIGESTIVE CANCERS</h5>
        <p class="card-text">I have dedicated all my entire scientific and clinical life to digestive cancer..</p>
        <p class="card-text" ><small class="text-muted">1/3/2024</small><span className="span2">Read more</span></p>
      </div>
      </div>
          
          <div class="card" style={{cursor:"pointer"}} onClick={BlogClick1}>
      <img class="card-img-top" src={surgery} alt="Card image cap"/>
      <div class="card-body">
        <h5 class="card-title">ADDRESSING A DRAWBACK OF MINIMALLY INVASIVE SURGERY</h5>
        <p class="card-text">The PALPABLE project introduces a tactile sensing device for use in Minimally Invasive Surgery
(MIS).</p>
        <p class="card-text" ><small class="text-muted">18/9/2023</small><span className="span2">Read more</span></p>
      </div>
      </div>
          </div>
   
        </div>
      </section>
      <section className="promo-section ptb-100" id='newsletter' style={{backgroundColor:'whitesmoke'}}>
        <div className="container">
          <div className="ro">
            <div className="col-lg">
            <h3 style={{color:'#12b59e'}}>
                  Newsletter
                  </h3>
                  <p><a style={{width:'100%',color:'',float:'left'}} href='https://us18.campaign-archive.com/?e=[UNIQID]&u=c9df0f816edea1f4d24fbbbdd&id=bd9735d849' target="_blank">1. The 1st PALPABLE project newsletter is here!</a>
</p>

<p><a style={{color:'',float:'left'}} href='https://mailchi.mp/123533e7fc00/the-1st-palpable-project-newsletter-is-here-12794305' target="_blank">2. Read our 2nd Newsletter, featuring the latest news and developments
</a>
</p>

<p style={{width:'100%',color:'',float:'left'}}><a style={{color:'',float:'left'}} href='https://mailchi.mp/bdcb44314799/the-1st-palpable-project-newsletter-is-here-12815353' target="_blank">3. Read our 3rd Newsletter!
</a>
</p>

<br/><br/><br/><br/><br/>

                  <SubscribeForm style={{justifyContent:'center'}}/>
            </div>
         
          
          </div>
   
        </div>
      </section>

      <Footer space />
    </Layout>
  );
}
