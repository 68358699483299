import React from "react";

import Footer from "../../components/layout/footer/Footer";
import Navbar from "../../components/layout/header/Navbar";
import Layout from "../../components/layout/Layout";
import PageHeader from "../../components/team/PageHeader";


import img2 from "../../logos/photos/image11.png"

import img1 from "../../logos/photos/image15.png"

import { useNavigate } from 'react-router-dom';

export default function OpticalSensors() {
  const navigate = useNavigate();
  function handleClick1() {

    navigate('/PALPABLE_project_kicks_off_with_a_bang');
  }
  function handleClick2() {

    navigate('/');
  }
  return (
    <Layout>
      <Navbar  />
      <PageHeader />
      <section id="about" className="about-us ptb-100 gray-light-bg">
        <div className="container">
          <div className="row align-items-center justify-content-between">
            <div className="col-md-12">
              <div className="about-content-left section-heading">
                <h3 style={{color:'#095a4f'}}>
                Engineering Flexibility: How Flexible Optical Sensors Could Transform Surgical Tools 
             </h3>
             <br/>
             <h5  style={{color:'#474545'}}>Introduction</h5>
             <p>The advancement of optical fiber sensors has made a great impact on precision monitoring and sensing technologies, with applications across various domains, including minimally invasive surgery (MIS). These sensors are particularly well suited for surgical applications due to their immunity to electromagnetic interference, compact dimensions and miniaturized sizes. One particular type, the fiber Bragg grating (FBG) sensor, which is capable of monitoring physical parameters such as strain, temperature and pressure, was introduced in the previous blog posts. </p>
            <p>In addition to their sensing capabilities, the miniaturization of optical systems has given rise to integrated optical and photonic sensing devices. Technologies such as “lab-on-a-chip”, which employ direct laser inscription and rapid prototyping, have the potential to expand the role of optical sensors in surgical procedures. The integration of optical sensors into surgical instruments has several advantages due to the minimal weight and diminutive dimensions of the sensors, enabling the utilization of the optical sensors in confined spaces where other sensing technologies are inapplicable. Furthermore, the miniature size and compatibility with a sterile environment bring a further added value. </p>
            
             <h5 style={{color:'#474545'}}>The Challenge for Minimally Invasive Surgery Application</h5>
             <p>MIS provides many benefits compared to traditional surgeries, including faster recovery time, reduced risks of infection and minimal scarring. The challenges remain for the surgeons during operation. Due to the lack of haptic feedback from robotic surgical systems, a flexible tactile sensor that can provide detailed information about the operating tissue can be beneficial to surgeons.  Consequently, there has been a growing interest in flexible sensors that are capable of quantifying various physical parameters, which are being considered for use in medical and robotic fields. Among these, polymer foil-based optical sensors have emerged as a compelling alternative. Their flexibility allows for effortless integration on soft and curved surfaces, while their biocompatibility and resistance to environmental stress make them ideal for incorporation into thin, flexible substrates. </p>
           
             <h5 style={{color:'#474545'}}>Material Selection and Sensor Fabrication</h5>
             <p>Typically, polymer optical waveguides can be patterned on a substrate that consists of silica glass or flexible polymers. In order to ensure optical confinement inside the waveguide, the substrate layer must possess a refractive index lower than the core material that forms the waveguide pattern. To enable an implementation on non-planar surfaces, thin flexible polymer foils were selected as a substrate, which makes it an optimal choice for applications such as wearable and biomedical sensors.</p>
             <p>The fabrication process starts with substrate preparation, where substrates are thoroughly cleaned using solvents such as acetone or isopropanol to remove surface contaminants. Before applying polymer, the substrate surface is typically activated by oxygen plasma to create a hydrophilic surface for optimal polymer adhesion. </p>
           
            <figure> <img
                  src={img1}
                  style={{borderRadius:'10px',width:'400px'}}
                  alt="about us"
                  className="img-fluid"
                />
                <figcaption style={{width:'420px', fontSize:'13px'}}>Figure 1: Microscopic image of a polymer waveguide fabricated with rapid prototyping</figcaption>
                </figure>
                <br/>
             <p>Next, the core waveguide patterning is performed using direct photolithography. The polymer material is spin-coated onto the substrate to create a uniform thickness of 50 µm. The coated substrate is then exposed to a defined waveguide pattern by a photolithography machine that defines the geometry. The unexposed areas are removed during the development process, leaving behind the desired waveguide pattern. Direct laser writing, on the other hand, uses precision lasers to inscribe the waveguide paths directly onto the substrate, eliminating the need for masks. This method is particularly suited for rapid prototyping and design modifications. The final step in the process is waveguide coupling, where pigtailed fibers are aligned and attached to the waveguide ends and fixed with adhesive. This ensures efficient light coupling and minimizes optical losses. Precision alignment tools are used to guarantee proper positioning of the connectors.</p>
             <p>When the flexible sensor foil indents an object, the external contact force will result in a waveguide deformation, that leads to a higher attenuation and a decrease of transmitted light signal. The sensing of external force can be realized by real-time monitoring of the light attenuation. By following this methodical approach, high-performance flexible polymer waveguide sensors can be manufactured efficiently, ready for applications in areas such as wearable electronics, healthcare, and environmental monitoring.</p>
             <figure> <img
                  src={img2}
                  style={{borderRadius:'10px',width:'400px'}}
                  alt="about us"
                  className="img-fluid"
                />
                <figcaption style={{width:'620px', fontSize:'13px'}}>Figure 2: A flexible sensor foil based on polymer waveguide</figcaption>
                </figure>
              
              </div>
            </div>
            <div className="col-md-5">
              <div className="about-content-right">
              
              </div>
            </div>
          </div>
        </div>
      </section>
  

      <Footer space />
    </Layout>
  );
}
